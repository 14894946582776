import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import Header from './layouts/Header';
import PageContainer from './layouts/PageContainer';
import Footer from './layouts/Footer';

export default function App() {
  return (
    <div className="flex h-auto w-full min-w-mobile flex-col items-center justify-center">
      <Header />
      <PageContainer />
      <Footer />
      <SpeedInsights />
      <Analytics />
    </div>
  );
}
