import interior1 from 'src/assets/imgs/interior1.png';
import interior2 from 'src/assets/imgs/interior2.png';
import interior3 from 'src/assets/imgs/interior3.png';
import SlideViewer, { ImageData } from 'src/components/SlideViewer';

interface InteriorProps {
  currentWidth: number;
}

const images: ImageData[] = [
  {
    id: 1,
    src: interior1,
    alt: '식당 내부사진1',
  },
  {
    id: 2,
    src: interior2,
    alt: '식당 내부사진2',
  },
  {
    id: 3,
    src: interior3,
    alt: '식당 내부사진3',
  },
];

export default function Interior({ currentWidth }: InteriorProps): JSX.Element {
  return (
    <section
      className="mt-10 flex h-auto w-full flex-col items-center tablet:mt-20"
      id="section-interior"
    >
      <article>
        <div className="w-full text-nowrap text-center font-title text-title-mobile text-title tablet:text-title-tablet">
          <p>{`"단체 예약이 가능한 넓은 공간"`}</p>
        </div>
      </article>
      <article className="mb-8 mt-16 flex w-full flex-col items-center">
        <SlideViewer images={images} currentWidth={currentWidth} />
      </article>
    </section>
  );
}
