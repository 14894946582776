export default function SignatureMenuViewerDescription(): JSX.Element {
  return (
    <div className="flex flex-col items-center text-nowrap font-title-sub text-description-tablet text-title-sub">
      <div className="flex justify-start">
        <p className="w-20">소고기</p>
        <p className="w-5">:</p>
        <p className="w-[24rem]">
          갈비살, LA갈비, 생등심, 소고기주물럭, 토시살, 우삼겹
        </p>
      </div>
      <div className="flex justify-start">
        <p className="w-20">돼지고기</p>
        <p className="w-5">:</p>
        <p className="w-[24rem]">갈매기살, 돼지갈비, 삼겹살, 목살, 항정살</p>
      </div>
      <div className="flex justify-start">
        <p className="w-20">그 외 재료</p>
        <p className="w-5">:</p>
        <p className="w-[24rem]">치즈 떡, 소세지, 각종야채</p>
      </div>
    </div>
  );
}
