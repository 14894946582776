import { useState, useEffect } from 'react';
import restaurantIcon from 'src/assets/imgs/restaurant-icon.png';
import {mapURL} from 'src/urlConfig';

function getMapDiv(): HTMLElement | string {
  const mapDiv = document.getElementById('map');
  if (mapDiv) {
    return mapDiv;
  }
  return '';
}

export default function NaverMaps(): JSX.Element {
  const [isMapLoad, setIsMapLoad] = useState(false);

  useEffect(() => {
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string | null;

    const mapLoadScript = document.createElement('script');
    mapLoadScript.type = 'text/javascript';
    mapLoadScript.setAttribute('strategy', 'beforeInteractive');
    mapLoadScript.src = `https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${CLIENT_ID}`;
    mapLoadScript.async = true;
    document.body.appendChild(mapLoadScript);

    mapLoadScript.onload = () => {
      setIsMapLoad(true);
    };
  }, []);

  useEffect(() => {
    if (isMapLoad) {
      const mapDiv = getMapDiv();

      const dinomeatPosition = new naver.maps.LatLng(33.47619, 126.48577);

      const mapOptions = {
        center: dinomeatPosition,
        zoom: 18,
        zoomControl: false,
      };
      const mapObj = new naver.maps.Map(mapDiv, mapOptions);

      mapObj.setMapTypeId(naver.maps.MapTypeId.NORMAL);

      const speechBubbleDinomeatInfo = [
        `<div class="iw_inner">
          <button
            onclick="window.open('${mapURL}', '_blank')"
            style="
              display: flex;
              color: white;
              background-color: #016AFB;
              border: none;
              border-radius: 9999px;
              justify-content: space-around;
              align-items: center;
              text-align:center;
              font-size: 1rem;
              width: 100%;
              height: 2.5rem;
              gap: 0.5rem;
              padding-left: 0.1rem;
              padding-right: 0.1rem;
              ">
            <div
              style="
                margin-left: 0.3rem;
                border-radius: 50%;
                border-width: 3px;
                border-color: #FFFFFF;
                background-color: #FFFFFF;
                display: flex;
                justify-content: center;
                overflow: hidden;
                width: 27px;
                height: 27px;
                ">
              <img
                src=${restaurantIcon}
                alt="식당 아이콘"
                style="
                  width: 18.89px;
                  height: 18.89px;"/>
            </div>
            <span
              style="
                margin-right: 0.5rem;
                white-space: nowrap;
              ">
              공룡고기 제주노형점
            </span>
          </button>
        </div>`,
      ].join('');

      const infowindow = new naver.maps.InfoWindow({
        content: speechBubbleDinomeatInfo,
        anchorSize: { width: 5, height: 5 },
        anchorColor: '#016AFB',
        borderWidth: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
      });
      infowindow.open(mapObj, dinomeatPosition);
    }
  }, [isMapLoad]);

  return (
    <div className="shadow-md h-full w-full flex flex-col items-center">
      <div
        id="map"
        className="h-full w-full"
      />
      <a
        target="_blank"
        rel="noreferrer"
        href={mapURL}
        className="flex h-16 w-full flex-col justify-center bg-white px-4 py-2 tablet:h-20"
      >
        <p className="text-info-title-mobile tablet:text-info-title-tablet">
          공룡고기 노형점
        </p>
        <p className="text-info-description-mobile text-title-sub tablet:text-info-description-tablet">
          제주특별자치도 제주시 과원로 22
        </p>
      </a>
    </div>
  );
}
