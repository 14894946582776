import homeIcon from 'src/assets/imgs/direction-home-icon.png';
import callIcon from 'src/assets/imgs/direction-call-icon.png';
import scheIcon from 'src/assets/imgs/direction-sche-icon.png';
import infoIcon from 'src/assets/imgs/direction-info-icon.png';

export default function Information(): JSX.Element {
  return (
    <section className="mt-10 flex h-auto w-full justify-center">
      <article className="flex h-40 w-80 flex-wrap items-center justify-center font-bold tablet:h-auto tablet:w-full tablet:flex-col tablet:flex-nowrap">
        <div className="flex w-auto justify-center tablet:flex-col">
          <div className="flex h-20 w-36 flex-col justify-center tablet:w-96">
            <div className="text-info-title-mobile text-title tablet:text-info-title-tablet">
              주소
            </div>
            <div className="flex items-center">
              <img className="m-2 h-4 w-4" src={homeIcon} alt="주소 아이콘" />
              <div className="flex flex-wrap text-info-description-mobile text-title-sub tablet:space-x-1 tablet:text-info-description-tablet">
                <p>제주 제주시 과원로 22</p>
                <p>1층 공룡고기</p>
              </div>
            </div>
          </div>
          <address className="flex h-20 w-36 flex-col justify-center not-italic tablet:w-96">
            <div className="text-info-title-mobile tablet:text-info-title-tablet">
              연락처
            </div>
            <div className="flex items-center">
              <img className="m-2 h-5 w-5" src={callIcon} alt="연락처 아이콘" />
              <div className="text-info-description-mobile text-title-sub tablet:text-info-description-tablet">
                <a href="tel:+050713715311">0507-1371-5311</a>
              </div>
            </div>
          </address>
        </div>
        <div className="flex w-auto justify-center tablet:flex-col">
          <div className="flex h-20 w-36 flex-col justify-center tablet:w-96">
            <div className="text-info-title-mobile text-title tablet:text-info-title-tablet">
              영업시간
            </div>
            <div className="flex items-center">
              <img
                className="m-2 h-5 w-5"
                src={scheIcon}
                alt="영업시간 아이콘"
              />
              <div className="flex flex-wrap text-info-description-mobile text-title-sub tablet:space-x-4 tablet:text-info-description-tablet">
                <p>매일 11 : 30 - 22 : 00</p>
                <p>라스트 오더 21 : 30</p>
              </div>
            </div>
          </div>
          <div className="flex h-20 w-36 flex-col justify-center tablet:w-96">
            <div className="text-info-title-mobile text-title tablet:text-info-title-tablet">
              서비스
            </div>
            <div className="flex items-center">
              <img className="m-2 h-5 w-5" src={infoIcon} alt="서비스 아이콘" />
              <div className="flex flex-wrap text-info-description-mobile text-title-sub tablet:space-x-1 tablet:text-info-description-tablet">
                <p>단체 이용 가능, 예약,</p>
                <p>free wifi, 주차</p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
