import mainLogo from 'src/assets/imgs/main-logo.png';

export default function Header(): JSX.Element {
  return (
    <header className="flex w-5/6 max-w-[81.25rem] flex-row items-center justify-center desktop:justify-between">
      <div className="h-auto w-auto">
        <a href="/">
          <img
            src={mainLogo}
            alt="로고"
            className="h-auto w-logo-banner-mobile tablet:w-logo-banner-tablet"
          />
        </a>
      </div>
      <div className="hidden space-x-10 text-info-title-tablet desktop:flex font-bold">
        <a href="#section-menu">메뉴</a>
        <a href="#section-reviews">리뷰</a>
        <a href="#section-interior">인테리어</a>
        <a href="#section-directions">위치</a>
      </div>
    </header>
  );
}
