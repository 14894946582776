import SignatureMenuViewerMobileAndTablet from 'src/components/SignatureMenuViewerMobileAndTablet';

interface SignatureMenuProps {
  currentWidth: number;
}

export default function SignatureMenu({
  currentWidth,
}: SignatureMenuProps): JSX.Element {
  return (
    <section
      className="mt-10 flex h-auto w-full flex-col items-center"
      id="section-menu"
    >
      <article>
        <div className="mb-2 w-full text-nowrap text-center font-title text-title-mobile text-title tablet:text-title-tablet">
          <p>{`"소와 돼지를 무한으로 즐길 수 있는 곳"`}</p>
        </div>
        <div className="mb-5 flex w-full flex-col items-center justify-center text-nowrap text-center font-title-sub text-description-mobile text-title-sub tablet:flex-row tablet:flex-wrap tablet:space-x-1 tablet:text-description-tablet">
          <p>100% 숯을 사용하여 6가지 종류의 소고기와 5가지 종류의</p>
          <p>돼지고기, 아이들을 위한 먹거리를 동시에 즐길수 있는 곳</p>
        </div>
      </article>
      <article className="flex items-center desktop:hidden">
        <div className="relative flex h-auto w-auto flex-row justify-center px-2">
          <SignatureMenuViewerMobileAndTablet currentWidth={currentWidth} />
        </div>
      </article>
    </section>
  );
}
