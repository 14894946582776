import mainLogo from 'src/assets/imgs/main-logo.png';

export default function Footer(): JSX.Element {
  return (
    <footer className="mt-14 flex w-full flex-col items-center">
      <div className="w-full border-t border-solid" />
      <div className="flex w-full max-w-[81.25rem] flex-col items-center justify-around">
        <div className="flex w-5/6 items-center justify-between">
          <div className="mainLogo">
            <img
              src={mainLogo}
              alt="로고"
              className="w-logo-small-mobile tablet:w-logo-small-tablet"
            />
          </div>
          <div className="flex flex-col p-2 text-banner-mobile text-title-sub tablet:text-banner-tablet">
            <div className="grid grid-cols-2 gap-x-4 gap-y-1">
              <p>상호명 : 공룡고기 제주노형점</p>
              <p>사업자 등록번호 : 637-14-01619</p>
              <p>주소 : 제주 제주시 과원로 22 1층 공룡고기</p>
              <p>대표 : 신중맹 </p>
            </div>
            <p>Copyright@2024 403LAB. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
