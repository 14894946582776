import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SlideViewerArrow from './SlideViewerArrow';

export interface ImageData {
  id: number;
  src: string; // Image URL or Image Data
  alt: string;
}

interface SlideShowProps {
  images: ImageData[];
  currentWidth: number;
}

const positions = ['left', 'center', 'right'];

export default function SlideShow({
  images,
  currentWidth,
}: SlideShowProps): JSX.Element {
  const [positionIds, setPositionIds] = useState([1]);
  const [animateButton, setAnimateButton] = useState(false);
  const [isMobile, setMobile] = useState(currentWidth <= 750);

  const leftImageVariants = isMobile
    ? {
        left: {
          x: '-30%',
          scale: 0.7,
          zIndex: 10,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
        center: {
          x: '0%',
          scale: 1,
          zIndex: 30,
        },
        right: {
          x: '30%',
          scale: 0.7,
          zIndex: 20,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
      }
    : {
        left: {
          x: '-80%',
          scale: 0.7,
          zIndex: 10,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
        center: {
          x: '0%',
          scale: 1,
          zIndex: 30,
        },
        right: {
          x: '80%',
          scale: 0.7,
          zIndex: 20,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
      };

  const rightImageVariants = isMobile
    ? {
        left: {
          x: '-30%',
          scale: 0.7,
          zIndex: 20,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
        center: {
          x: '0%',
          scale: 1,
          zIndex: 30,
        },
        right: {
          x: '30%',
          scale: 0.7,
          zIndex: 10,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
      }
    : {
        left: {
          x: '-80%',
          scale: 0.7,
          zIndex: 20,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
        center: {
          x: '0%',
          scale: 1,
          zIndex: 30,
        },
        right: {
          x: '80%',
          scale: 0.7,
          zIndex: 10,
          backgroundColor: '#000000',
          opacity: 0.5,
        },
      };

  const [imageVariants, setImageVariants] = useState(leftImageVariants);

  useEffect(() => {
    setPositionIds(
      Array.from({ length: images.length }, (_, index) => index + 1),
    );
  }, [images]);

  function handleLeftButton() {
    setPositionIds(prev => {
      const updatedIds = prev.map(id => {
        if (id <= 1) {
          return positionIds.length;
        }
        return id - 1;
      });
      return updatedIds;
    });
  }

  function handleRightButton() {
    setPositionIds(prev => {
      const updatedIds = prev.map(id => {
        if (id >= positionIds.length) {
          return 1;
        }
        return id + 1;
      });
      return updatedIds;
    });
  }

  useEffect(() => {
    setMobile(currentWidth <= 750);
  }, [currentWidth]);

  useEffect(() => {
    if (isMobile) {
      setImageVariants(leftImageVariants);
    } else {
      setImageVariants(rightImageVariants);
    }
  }, [isMobile]);

  return (
    <div className="relative flex h-40 w-full justify-center overflow-x-clip bg-black tablet:h-48 desktop:h-64">
      {images.map((image: ImageData) => (
        <motion.img
          key={image.id}
          src={image.src}
          alt={image.alt}
          className="absolute -top-4 h-48 w-auto transform rounded-2xl tablet:h-56 desktop:h-72"
          initial="center"
          animate={positions[positionIds.indexOf(image.id)]}
          variants={imageVariants}
          transition={{ duration: 0.5 }}
        />
      ))}
      <button
        type="button"
        onClick={() => {
          handleLeftButton();
          setImageVariants(leftImageVariants);
          setAnimateButton(true);
        }}
        className="relative -left-[3rem] -top-[1rem] z-40 h-48 w-32 tablet:-left-[9rem] tablet:top-0 tablet:h-48 tablet:w-56 desktop:-left-[11rem] desktop:h-64 desktop:w-72"
        aria-label="left arrow button"
      >
        <div
          className={`absolute right-1/4 top-0 h-full w-[3.43rem] rounded-l-2xl bg-black bg-opacity-50 tablet:hidden ${animateButton ? 'animate-button' : ''}`}
          onAnimationEnd={() => setAnimateButton(false)}
        />
        <SlideViewerArrow
          direction="left"
          className="left-[3.5rem] h-[3.5rem] w-[1.86rem] -translate-y-1/2 tablet:left-[5rem] tablet:h-[5rem] tablet:w-[2.656rem] desktop:left-[6rem] desktop:h-[6rem] desktop:w-[3.188rem]"
        />
      </button>
      <button
        type="button"
        onClick={() => {
          handleRightButton();
          setImageVariants(rightImageVariants);
          setAnimateButton(true);
        }}
        className=" relative -right-[3rem] -top-[1rem] z-40 h-48 w-32 tablet:-right-[9rem] tablet:top-0 tablet:h-48 tablet:w-56 desktop:-right-[11rem] desktop:h-64 desktop:w-72"
        aria-label="right arrow button"
      >
        <div
          className={`absolute left-1/4 top-0 h-full w-[3.43rem] rounded-r-2xl bg-black bg-opacity-50 tablet:hidden ${animateButton ? 'animate-button' : ''}`}
          onAnimationEnd={() => setAnimateButton(false)}
        />
        <SlideViewerArrow
          direction="right"
          className="right-[3.5rem] h-[3.5rem] w-[1.86rem]  -translate-y-1/2 tablet:right-[5rem] tablet:h-[5rem] tablet:w-[2.656rem] desktop:right-[6rem] desktop:h-[6rem] desktop:w-[3.188rem]"
        />
      </button>
    </div>
  );
}
