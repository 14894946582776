import soyBeanPasteStew from '../assets/imgs/menu-soyBeanPasteStew.png';
import steamedEggs from '../assets/imgs/menu-steamedEggs.png';
import steamedRice from '../assets/imgs/menu-steamedRice.png';
import spicyNoodle from '../assets/imgs/menu-spicyNoodle.png';
import MenuboardContour from '../components/MenuboardContour';
import SignatureMenuViewerDesktop from '../components/SignatureMenuViewerDesktop';

export default function Menuboard(): JSX.Element {
  return (
    <section className="mt-10 flex h-auto w-full flex-col items-center">
      <article className="mx-1 flex h-full w-full flex-col items-center px-7">
        <div className="flex h-auto w-full flex-col">
          <div className="flex h-auto w-full items-center justify-center text-center font-menu text-title-mobile text-menu tablet:text-title-tablet">
            <p>메뉴판</p>
          </div>
            <MenuboardContour />
        </div>
        <article className="flex h-full w-auto items-center justify-center tablet:rounded-md tablet:border-x-2 tablet:border-menu-frame tablet:px-7 desktop:h-[51rem]">
          <div className="relative h-[29.5rem] w-1 bg-menu-frame tablet:hidden">
            <div className="absolute inset-x-0 bottom-0 flex h-auto w-auto justify-center">
              <div className="absolute h-3 w-3 rounded-full bg-menu-frame" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="h-auto w-full py-2 text-center font-menu text-[1.35rem] text-menu">
              <p>미트바 무한리필</p>
            </div>
            <article className="w-[19rem] tablet:w-[26rem] desktop:h-[7.6rem]">
              <div className="flex h-auto w-full justify-between px-5 text-title">
                <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                  성인
                </p>
                <p className="w-[5rem] text-center text-menu-price-mobile  font-bold text-primary tablet:text-menu-price-tablet">
                  29,900원
                </p>
              </div>
              <div className="flex h-auto w-full justify-between px-5 text-title">
                <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                  초등학생/80세 이상
                </p>
                <p className="w-[5rem] text-center text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                  18,900원
                </p>
              </div>
              <div className="flex h-auto w-full justify-between px-5 text-title">
                <p className="text-menu-title-mobile font-bold  tablet:text-menu-title-tablet">
                  유아 4세 이상
                </p>
                <p className="w-[5rem] text-center text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                  6,000원
                </p>
              </div>
              <div className="flex h-auto w-full justify-between px-5 text-title">
                <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                  4세 이하
                </p>
                <p className="w-[5rem] text-center text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                  무료
                </p>
              </div>
            </article>
            <article className="hidden mt-10 px-2 h-auto w-auto desktop:flex desktop:flex-col">
              <SignatureMenuViewerDesktop />
            </article>
            <article className="desktop:[11.25rem] mt-10 justify-center tablet:mt-7 tablet:w-[40rem] tablet:rounded-md	tablet:border-2 tablet:border-solid tablet:border-menu-frame tablet:p-1">
              <div className="h-auto w-full text-center font-menu text-[1.35rem] text-menu tablet:my-1">
                <p>후식 메뉴</p>
              </div>
              <article className="flex h-auto w-[19rem] flex-wrap items-center justify-center tablet:w-[38rem]">
                <div className="h-full w-full tablet:w-[19rem]">
                  <div className="my-1 flex h-auto w-full items-center justify-between px-3">
                    <img
                      src={spicyNoodle}
                      alt="물냉면/비빔냉면"
                      className="h-16 w-16 rounded-md"
                    />
                    <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                      물/비빔
                    </p>
                    <p className="text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                      6,000원
                    </p>
                  </div>
                  <div className="my-1 flex h-auto w-full items-center justify-between px-3">
                    <img
                      src={steamedEggs}
                      alt="계란찜"
                      className="h-16 w-16 rounded-md"
                    />
                    <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                      계란찜
                    </p>
                    <p className="text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                      4,000원
                    </p>
                  </div>
                </div>
                <div className="h-full w-full tablet:w-[19rem]">
                  <div className="my-1 flex h-auto w-full items-center justify-between px-3">
                    <img
                      src={steamedRice}
                      alt="공기밥"
                      className="h-16 w-16 rounded-md"
                    />
                    <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                      공기밥
                    </p>
                    <p className="text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                      1,000원
                    </p>
                  </div>
                  <div className="my-1 flex h-auto w-full items-center justify-between px-3">
                    <img
                      src={soyBeanPasteStew}
                      alt="된장찌개"
                      className="h-16 w-16 rounded-md"
                    />
                    <p className="text-menu-title-mobile font-bold tablet:text-menu-title-tablet">
                      된장찌개
                    </p>
                    <p className="text-menu-price-mobile font-bold text-primary tablet:text-menu-price-tablet">
                      4,000원
                    </p>
                  </div>
                </div>
              </article>
            </article>
          </div>
          <div className="relative h-[29.5rem] w-1 bg-menu-frame tablet:hidden">
            <div className="absolute inset-x-0 bottom-0 flex h-auto w-auto justify-center">
              <div className="absolute h-3 w-3 rounded-full bg-menu-frame" />
            </div>
          </div>
        </article>
      </article>
    </section>
  );
}
