import NaverMaps from 'src/components/NaverMaps';
import restaurantView from 'src/assets/imgs/restaurant-view.png';

export default function Directions(): JSX.Element {
  return (
    <section
      className="mt-10 flex h-auto w-full flex-col items-center tablet:mt-20"
      id="section-directions"
    >
      <article>
        <div className="font-title w-full text-nowrap text-center text-title-mobile text-title tablet:text-title-tablet">
            <p>{`"공룡고기 제주 노형점으로"`} </p>
        </div>
        <div className="font-title-sub mt-2 flex w-full flex-col items-center text-nowrap text-center text-description-mobile text-title-sub tablet:text-description-tablet desktop:hidden">
          <p>돼지고기, 소고기 무한리필은 제주 노형 공룡고기</p>
        </div>
      </article>
      <article className="px-5">
        <div className="mt-5 desktop:hidden">
          <img
            className="w-full max-w-[43.75rem]"
            src={restaurantView}
            alt="식당 전경"
          />
        </div>
        <div className="mt-5 flex justify-center w-full  h-[16rem] min-w-[20rem] max-w-[43.75rem] desktop:w-[43.75rem] tablet:h-[20rem] desktop:h-[30rem]">
          <NaverMaps />
        </div>
      </article>
    </section>
  );
}
