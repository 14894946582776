import leftMenu from 'src/assets/imgs/signature-menu-left.png';
import rightMenu from 'src/assets/imgs/signature-menu-right.png';
import midMenu from 'src/assets/imgs/signature-menu-mid.png';
import SignatureMenuViewerDescription from './SignatureMenuViewerDescription';

export default function SignatureMenuViewerDesktop(): JSX.Element {
  return (
    <div>
      <div className="relative flex flex-row h-auto w-auto justify-center space-x-10">
        <div className="relative flex h-full w-full">
          <img
            src={leftMenu}
            alt="소고기"
            className="h-[15.625rem] w-[15.625rem] object-cover"
          />
        </div>
        <div className="relative flex h-full w-full">
          <img
            src={rightMenu}
            alt="돼지고기"
            className="h-[15.625rem] w-[15.625rem] object-cover"
          />
        </div>
        <div className="relative flex h-full w-full">
          <img
            src={midMenu}
            alt="치즈떡과 소세지"
            className="h-[15.625rem] w-[15.625rem] tablet:object-cover"
          />
        </div>
      </div>
      <div className="mt-5">
        <SignatureMenuViewerDescription />
      </div>
    </div>
  );
}
