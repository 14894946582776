/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import MainTitleBanner from 'src/pages/MainTitleBanner';
import SignatureMenu from 'src/pages/SignatureMenu';
import Menuboard from 'src/pages/Menuboard';
import Interior from 'src/pages/Interior';
import CustomerReviews from 'src/pages/CustomerReviews';
import Directions from 'src/pages/Directions';
import Information from 'src/pages/Information';

export default function PageContainer(): JSX.Element {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {}, [windowWidth]);

  return (
    <main className="flex h-auto w-full flex-col justify-center">
      <MainTitleBanner />
      <SignatureMenu currentWidth={windowWidth} />
      <Menuboard />
      <CustomerReviews />
      <Interior currentWidth={windowWidth} />
      <Directions />
      <Information />
    </main>
  );
}
