import mainTitleBanner from 'src/assets/imgs/main-title-banner.png';

export default function MainTitleBanner(): JSX.Element {
  return (
    <section className="hidden h-auto w-full items-center desktop:flex desktop:flex-row">
      <article className="flex h-auto w-full justify-center">
        <img
          src={mainTitleBanner}
          alt="식당 전경"
          className="h-auto w-full object-contain"
        />
      </article>
    </section>
  );
}
