import review from 'src/assets/imgs/review.png';
import blogIcon from 'src/assets/imgs/review-blog-icon.png';
import starIcon from 'src/assets/imgs/review-star-icon.png';
import {reviewURL} from 'src/urlConfig';

export default function CustomerReviews(): JSX.Element {
  return (
    <section
      className="mt-10 flex h-auto w-full flex-col items-center tablet:mt-20"
      id="section-reviews"
    >
      <div className="font-title w-full text-nowrap text-center text-title-mobile text-title tablet:text-title-tablet">{`"인정받은 맛집"`}</div>
      <div className="font-title-sub mt-2 flex w-full flex-col items-center text-nowrap text-center text-description-mobile text-title-sub tablet:text-description-tablet">
        <p>네이버 리뷰를 통한 진실한 소리</p>
      </div>
      <div className="flex flex-col items-center desktop:flex-row">
        <img
          className="h-full w-full max-w-[43.75rem] px-5 tablet:px-10"
          src={review}
          alt="리뷰"
        />
        <article className="mt-5 flex w-full h-full justify-center items-center">
          <a
            target="_blank"
            rel="noreferrer"
            href={reviewURL}
            className ="flex w-1/2 justify-around text-info-description-mobile font-bold tablet:text-info-description-tablet desktop:mt-0 desktop:flex-col desktop:items-center desktop:w-full"
          >
            <div className="font-bold font-title-sub flex flex-col items-center">
              <img
                className="h-10 w-10 tablet:h-16 tablet:w-16"
                src={blogIcon}
                alt="리뷰 아이콘"
              />
              <p className="whitespace-nowrap">블로그 리뷰</p>
              <p>100+</p>
            </div>
            <div className="mt-5 hidden desktop:flex" />
            <div className="font-bold font-title-sub flex flex-col items-center">
              <img
                className="h-10 w-10 tablet:h-16 tablet:w-16"
                src={starIcon}
                alt="평점 아이콘"
              />
              <p className="whitespace-nowrap">평점</p>
              <p>4.14/5</p>
            </div>
          </a>
        </article>
      </div>
    </section>
  );
}
