
interface SlideArrowProps {
  direction: 'left' | 'right';
  className?: string;
}

export default function SlideViewerArrow({
  direction,
  className,
}: SlideArrowProps): JSX.Element {
  function getArrowClassName() {
    if (direction === 'left') {
      return `absolute ${className} rotate-180`;
    } // direction === 'right'
    return `absolute ${className}`;
  }
  const arrowClassName = getArrowClassName();

  return (
    <div className={arrowClassName}>
      <svg viewBox="0 0 34 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          id="Vector"
          d="M2.23438 61.8411L30.6432 33.42C31.5096 32.5532 31.5096 31.1483 30.6432 30.2815L2.23438 1.86041"
          stroke="#C7C7C7"
          strokeWidth={4.43936}
        />
      </svg>
    </div>
  );
}

SlideViewerArrow.defaultProps = {
  className: '',
};
