import leftMenu from 'src/assets/imgs/signature-menu-left.png';
import rightMenu from 'src/assets/imgs/signature-menu-right.png';
import midMenu from 'src/assets/imgs/signature-menu-mid.png';
import SignatureMenuViewerDescription from './SignatureMenuViewerDescription';

interface SignatureMenuViewerMobileAndTabletProps {
  currentWidth: number;
}

export default function SignatureMenuViewerMobileAndTablet({
  currentWidth,
}: SignatureMenuViewerMobileAndTabletProps): JSX.Element {
  function calculateBottomMargin(width: number): string {
    if (width >= 750) {
      // 750px is the width of the tablet
      return '1rem';
    }
    if (width >= 360) {
      return `${3 + (width - 360) / 120}rem`;
    }
    // (width < 360)
    return '3rem';
  }

  return (
    <div className="flex flex-col items-center">
      <div
        style={{
          marginBottom: calculateBottomMargin(currentWidth),
        }}
        className="relative flex h-auto w-auto flex-row justify-center px-2 tablet:h-[18.3125rem]"
      >
        <div className="relative flex h-full w-full justify-start">
          <img
            src={leftMenu}
            alt="돼지고기"
            className="h-full w-full object-cover"
          />
          <div className="absolute flex h-full w-20 flex-col items-center justify-around bg-image-sub bg-opacity-50 font-title-sub text-signature-menu-mobile font-bold text-white tablet:hidden">
            <p>갈매기살</p>
            <p>돼지갈비</p>
            <p>삼겹살</p>
            <p>목살</p>
            <p>항정살</p>
          </div>
        </div>
        <div className="relative flex h-full w-full justify-end">
          <img
            src={rightMenu}
            alt="소고기"
            className="h-full w-full object-cover"
          />
          <div className="absolute flex h-full w-20 flex-col items-center justify-around bg-image-sub bg-opacity-50 font-title-sub text-signature-menu-mobile font-bold text-white tablet:hidden">
            <p>갈비살</p>
            <p>LA갈비</p>
            <p>생등심</p>
            <p>소고기주물럭</p>
            <p>토시살</p>
            <p>우삼겹</p>
          </div>
        </div>
        <div className="absolute -bottom-1/3 flex h-4/6 w-[25.5%] overflow-hidden rounded-full tablet:relative tablet:inset-0 tablet:h-full tablet:w-full tablet:rounded-none">
          <img
            src={midMenu}
            alt="치즈떡과 소세지"
            className="h-full w-full tablet:object-cover"
          />
          <div className="absolute -bottom-1/3 flex h-4/6 w-full flex-col items-center justify-start bg-image-sub bg-opacity-50 pt-1 font-title-sub text-signature-menu-mobile font-bold text-white tablet:hidden">
            <p>치즈떡과 소세지</p>
          </div>
        </div>
      </div>
      <div className="hidden tablet:flex">
        <SignatureMenuViewerDescription />
      </div>
    </div>
  );
}
