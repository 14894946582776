export default function MenuboardContour(): JSX.Element {
  return (
    <div className="flex h-full w-full justify-center">
      <svg
        className="h-full w-full tablet:h-2/3 tablet:w-4/5"
        viewBox="0 0 701 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.91 31.0528H95.1229L88.3776 50.9597H106.247L112.91 31.0528ZM124.572 50.9597H672.312V50.9564C672.463 50.9586 672.613 50.9597 672.765 50.9597C688.007 50.9597 700.363 39.6164 700.363 25.6236C700.363 11.6308 688.007 0.287476 672.765 0.287476C657.522 0.287476 645.166 11.6308 645.166 25.6236C645.166 27.4872 645.385 29.3037 645.801 31.0528H131.317L124.572 50.9597ZM58.9282 31.0528H76.7153L70.0523 50.9597H52.1829L58.9282 31.0528ZM40.7572 30.3462C24.7664 28.0312 11.7084 20.23 5.92691 9.93422C2.21527 14.2491 0 19.6989 0 25.6237C0 39.4778 12.1124 50.7347 27.1458 50.9565V50.9597H27.5202L27.5983 50.9598L27.6763 50.9597H33.8576L40.7572 30.3462Z"
          fill="url(#paint0_linear_991_1786)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_991_1786"
            x1="700.363"
            y1="31.0527"
            x2="-7.69134"
            y2="36.9344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#955958" />
            <stop offset="1" stopColor="#CD0300" stopOpacity="0.42" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
